import {
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  Group,
  NativeSelect,
  rem,
  Stack,
  Text,
  TextInput,
  useCombobox,
  useMantineTheme,
  useMatches,
} from "@mantine/core";
import { DatePickerInput, DatesProvider } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconChevronDown, IconMinus, IconPlus } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { PageContent } from "../../../Model/PageContent.model";
import { ServicesData } from "../../../Model/ServicesData.model";
import { StdCode } from "../../../Model/StdCode.model";
import { getStdCodes } from "../../../Services/content.service";
import DescriptionText from "../DescriptionText";
import { HugeTitle } from "../HugeTitle";
import "./GetHelpForm.css";

interface GetHelpFormProps {
  isLoading: boolean;
  onSubmit: (args: any) => void;
  serviceData?: ServicesData;
  forceClearForm?: boolean;
}

function GetHelpForm({
  isLoading,
  onSubmit,
  serviceData,
  forceClearForm,
}: GetHelpFormProps) {
  const theme = useMantineTheme();

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [
    shouldShowAdditionalDetailsFields,
    { toggle: toggleAdditionalDetailsFields },
  ] = useDisclosure(false);
  const [selectedStdCode, setSelectedStdCode] = useState<string | null>(null);

  const [stdCodes, setStdCodes] = useState<StdCode[]>([]);

  const hugeTitleStyles = useMatches({
    base: {
      fontSize: "1.1rem",
      fontWeight: 570,
    },
    md: {
      fontSize: "1.6rem",
      fontWeight: 600,
    },
  });

  const desktopMediaQuery = useMediaQuery(
    `(min-width: ${theme.breakpoints.md})`
  );
  async function loadStdCodes() {
    try {
      const response = await getStdCodes();

      if (response) {
        const initialStdCode =
          response.find((stdCode: StdCode) => stdCode.dialCode === "+1")
            ?.dialCode ?? null;
        setSelectedStdCode(initialStdCode);
        form.setFieldValue("stdCode", initialStdCode);
      }

      setStdCodes(response);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (forceClearForm) {
      form.reset();
    }
  }, [forceClearForm]);

  useEffect(() => {
    loadStdCodes();
  }, []);

  useEffect(() => {
    form.setValues({
      service: serviceData?.defaultService
        ? serviceData?.defaultService?.header
        : "",
    });
  }, [serviceData?.defaultService]);

  // function getFlagFromDialCode(dialCode: string | null) {
  //   return (
  //     stdCodes.find((stdCode: StdCode) => stdCode.dialCode === dialCode)
  //       ?.flag ?? ""
  //   );
  // }

  // function getRenderableSTDCodes() {
  //   if (stdCodes?.length) {
  //     return stdCodes.map((stdCode: StdCode) => (
  //       <Combobox.Option value={stdCode.dialCode} key={stdCode.dialCode}>
  //         <Text
  //           size="md"
  //           c={"dimmed"}
  //         >{`${stdCode.flag} ${stdCode.dialCode}`}</Text>
  //       </Combobox.Option>
  //     ));
  //   } else {
  //     return [];
  //   }
  // }

  function getRenderableSTDCodesForNative() {
    if (stdCodes?.length) {
      return stdCodes.map((stdCode: StdCode, index: number) => (
        <option value={stdCode.dialCode} key={stdCode.dialCode + "-" + index}>
          {`${stdCode.flag} ${stdCode.dialCode}`}
        </option>
      ));
    } else {
      return [];
    }
  }

  const form = useForm<any>({
    initialValues: {
      usersName: "",
      service: "",
      // title: "",
      email: "",
      stdCode: selectedStdCode,
      contactNumber: "",
      allowMarketingNotifications: true,
      deadline: null,
    },

    validate: {
      service: isNotEmpty("Please select a service"),
      usersName: isNotEmpty("Please provide you name"),
      // title: isNotEmpty("Assignment title is needed"),
      email: (value: any) => {
        console.log(value);
        if (!value?.trim()) {
          return null;
        } else {
          return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value)
            ? null
            : "Enter a valid Email ";
        }
      },
      contactNumber: (value: any) =>
        /^[\d\s+-]+$/.test(value)
          ? null
          : `Phone number must be valid and should not contain any special characters`,
    },
  });

  function renderAdditionalFieldsConditionally() {
    if (shouldShowAdditionalDetailsFields) {
      return [
        <TextInput
          key={1}
          size={desktopMediaQuery ? "lg" : "md"}
          placeholder="Your Email "
          {...form.getInputProps("email")}
          disabled={isLoading}
          type="email"
        />,
        <DatesProvider settings={{ timezone: "UTC" }} key={2}>
          <DatePickerInput
            valueFormat="DD MMM YYYY"
            dropdownType="modal"
            placeholder="When is the deadline ?"
            size={desktopMediaQuery ? "lg" : "md"}
            {...form.getInputProps("deadline")}
            radius={"md"}
            disabled={isLoading}
            clearable
            minDate={new Date()}
            styles={{
              placeholder: {
                fontSize: desktopMediaQuery
                  ? theme.fontSizes.lg
                  : theme.fontSizes.md,
              },
              input: {
                paddingTop: desktopMediaQuery ? theme.spacing.sm : "0.55rem",
                paddingBottom: desktopMediaQuery ? theme.spacing.sm : "0.55rem",
              },
            }}
          />
        </DatesProvider>,
      ];
    } else {
      return [];
    }
  }

  function getServicesDropdownOptions() {
    return [
      <option value="" key={"service - 0"}>
        Choose a service
      </option>,
      ...(serviceData?.services?.map((service: PageContent, index: number) => (
        <option value={service.header} key={"service - " + index + 1}>
          {service.header}
        </option>
      )) ?? []),
    ];
  }

  return (
    <Card className="fade-in">
      <Stack gap={15}>
        <Stack gap={3}>
          <HugeTitle style={hugeTitleStyles}>Request For Help</HugeTitle>
          <DescriptionText c="dimmed" size="xs">
            Send us your request, we'll reply ASAP!
          </DescriptionText>
        </Stack>
        <Divider mb={10} />
        <form
          onSubmit={form.onSubmit(onSubmit)}
          style={{ height: "100%", width: "100%" }}
        >
          <Stack
            style={{ width: "100%", height: "100%" }}
            justify="center"
            gap={desktopMediaQuery ? 20 : 15}
          >
            <NativeSelect
              size={desktopMediaQuery ? "lg" : "md"}
              {...form.getInputProps("service")}
              disabled={isLoading}
            >
              {getServicesDropdownOptions()}
            </NativeSelect>
            {/*<TextInput
              size={desktopMediaQuery ? "lg" : "md"}
              placeholder="Assignment Title *"
              {...form.getInputProps("title")}
              disabled={isLoading}
            /> */}

            <TextInput
              size={desktopMediaQuery ? "lg" : "md"}
              placeholder="Your name please *"
              {...form.getInputProps("usersName")}
              disabled={isLoading}
            />
            <Grid gutter={0}>
              <Grid.Col
                span={{
                  base: 4,
                  md: 3,
                }}
              >
                {/* <Combobox
                    store={combobox}
                    onOptionSubmit={(val) => {
                      setSelectedStdCode(val);
                      combobox.closeDropdown();
                      form.setFieldValue("stdCode", val);
                    }}
                  >
                    <Combobox.Target>
                      <Button
                        variant="outline"
                        fullWidth
                        type="button"
                        rightSection={<Combobox.Chevron />}
                        onClick={() => combobox.toggleDropdown()}
                        size={desktopMediaQuery ? "lg" : "md"}
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          background: "inherit",
                          borderColor: theme.colors.gray[4],
                          borderRight: "none",
                        }}
                        pr={3}
                      >
                        <Text size="md" c="dimmed" visibleFrom="md">
                          {getFlagFromDialCode(selectedStdCode)} {selectedStdCode}
                        </Text>
  
                        <Group hiddenFrom="md" gap={2}>
                          <Text
                            size="xs"
                            c="dimmed"
                            hiddenFrom="md"
                            style={{ display: "inlint" }}
                          >
                            {getFlagFromDialCode(selectedStdCode)}
                          </Text>
                          <Text
                            size="sm"
                            c="dimmed"
                            hiddenFrom="md"
                            style={{ display: "inlint" }}
                          >
                            {selectedStdCode}
                          </Text>
                        </Group>
                      </Button>
                    </Combobox.Target>
  
                    <Combobox.Dropdown mah={300} style={{ overflowY: "auto" }}>
                      <Combobox.Options>
                        {getRenderableSTDCodes()}
                      </Combobox.Options>
                    </Combobox.Dropdown>
                  </Combobox> */}

                <NativeSelect
                  size={desktopMediaQuery ? "lg" : "md"}
                  {...form.getInputProps("stdCode")}
                  disabled={isLoading || !stdCodes?.length}
                  styles={{
                    input: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      background: "inherit",
                      borderColor: theme.colors.gray[4],
                      borderRight: "none",
                      paddingInlineEnd: 0,
                    },
                    section: {
                      padding: 1,
                    },
                  }}
                  rightSection={
                    <IconChevronDown
                      style={{ width: rem(16), height: rem(16) }}
                    />
                  }
                >
                  {getRenderableSTDCodesForNative()}
                </NativeSelect>
              </Grid.Col>
              <Grid.Col
                span={{
                  base: 8,
                  md: 9,
                }}
              >
                <TextInput
                  type="tel"
                  size={desktopMediaQuery ? "lg" : "md"}
                  placeholder="Phone Number or Whatsapp Number *"
                  {...form.getInputProps("contactNumber")}
                  disabled={isLoading || !stdCodes?.length}
                  styles={{
                    input: {
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    },
                  }}
                />
              </Grid.Col>
            </Grid>

            {renderAdditionalFieldsConditionally()}

            <Group>
              <Button
                p={0}
                leftSection={
                  shouldShowAdditionalDetailsFields ? (
                    <IconMinus size={13} />
                  ) : (
                    <IconPlus size={13} />
                  )
                }
                c="blue"
                fullWidth={false}
                variant="white"
                size="xs"
                onClick={toggleAdditionalDetailsFields}
              >
                <Text size="xs" style={{ display: "inline" }} m={0} p={0}>
                  ADDITIONAL DETAILS
                </Text>
              </Button>
            </Group>
            <Checkbox
              defaultChecked
              label={
                <Text c={"dimmed"} size={desktopMediaQuery ? "sm" : "xs"}>
                  Keep me updated with tips, hacks, and offers!
                </Text>
              }
              color="teal"
              radius="md"
              {...form.getInputProps("allowMarketingNotifications")}
              disabled={isLoading}
              size={desktopMediaQuery ? "md" : "xs"}
            />

            <Button
              size={desktopMediaQuery ? "lg" : "md"}
              type="submit"
              loading={isLoading}
            >
              Get Help Now
            </Button>
          </Stack>
        </form>
      </Stack>
    </Card>
  );
}

export default GetHelpForm;
