import emailjs from "@emailjs/browser";
import {
  Chip,
  Container,
  Grid,
  Group,
  List,
  ListItem,
  rem,
  Stack,
  Text,
  useMantineTheme,
  useMatches,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { PageContent } from "../../Model/PageContent.model";
import { ServicesData } from "../../Model/ServicesData.model";
import { createEnquiry } from "../../Services/enquiry.service";
import Metrics from "../Metrics";
import AlertModal from "../Shared/AlertModal";
import DescriptionText from "../Shared/DescriptionText";
import GetHelpForm from "../Shared/GetHelpForm";
import { HugeTitle } from "../Shared/HugeTitle";
import { useRef, useState } from "react";
import { useEffect } from "react";
import "./Feature.css";
import {
  IconCircleCheck,
  IconCircleCheckFilled,
  IconClipboardOff,
  IconLockPassword,
  IconRobotOff,
} from "@tabler/icons-react";
import { logEvent } from "../../Services/analytics.service";

interface FeatureProps {
  pageContent?: PageContent;
  serviceData?: ServicesData;
}

export default function Feature({ pageContent, serviceData }: FeatureProps) {
  const theme = useMantineTheme();
  const desktopMediaQuery = useMediaQuery(
    `(min-width: ${theme.breakpoints.md})`
  );
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [isEmailSent, { open: emailSent, close: emailNotSent }] =
    useDisclosure(false);
  const [isSendingEmail, { open: setSendingEmail, close: hasSentEmail }] =
    useDisclosure(false);
  const [
    shouldForceClearForm,
    { open: forceClearForm, close: notToClearForm },
  ] = useDisclosure(false);
  const [
    shouldShowAlertModal,
    { open: showAlertModal, close: closeAlertModal },
  ] = useDisclosure(false);

  const animationServices = useRef([
    "Dissertation",
    "Programming",
    "Research",
    "Development",
    "Projects",
    "Assignments",
  ]);

  const hugeTitleStyles = useMatches({
    base: {
      fontSize: "1.6rem",
      textAlign: "center",
      fontWeight: 500,
    },
    md: {
      fontSize: "3.2rem",
      fontWeight: 350,
    },
  });

  const animatedServiceStyles = useMatches({
    base: {
      fontSize: "2.5rem",
      textAlign: "center",
      fontWeight: 600,
    },
    md: {
      fontSize: "3.2rem",
      fontWeight: 600,
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIsSliding(true); // Trigger slide-out animation
      setTimeout(() => {
        setCurrentServiceIndex(
          (prevIndex) => (prevIndex + 1) % animationServices.current.length
        ); // Move to the next service
        setIsSliding(false); // Trigger slide-in animation
      }, 1000); // Duration of slide-out animation
    }, 5000); // Total time each service is visible

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  async function sendMail(
    usersName: string,
    service: string,
    stdCode: string,
    contactNumber: string,
    allowMarketingNotifications: boolean,
    deadline?: any,
    email?: string
  ) {
    setSendingEmail();
    notToClearForm();
    try {
      const providedEmail = email ?? "Not Provided";
      const providedDeadline = deadline ?? "Not Provided";
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
        {
          service_type: service,
          project_title: "N/A",
          users_name: usersName,
          contact_number: `${stdCode} ${contactNumber}`,
          deadline: providedDeadline,
          email: providedEmail,
        }
      );
      try {
        await createEnquiry(
          service,
          usersName,
          stdCode,
          contactNumber,
          providedEmail,
          allowMarketingNotifications,
          providedDeadline
        );
        console.log("Enquired");
      } catch (e) {
        console.log("No enquiry, created !!");
      }
      emailSent();
      forceClearForm();
    } catch (e) {
      console.log(e);
      emailNotSent();
    } finally {
      hasSentEmail();
      showAlertModal();
    }
  }

  const handleSubmit = (values: any) => {
    logEvent("conversion");
    sendMail(
      values.usersName,
      values.service,
      values.stdCode,
      values.contactNumber,
      values.allowMarketingNotifications,
      values.deadline,
      values.email
    );
  };

  return (
    <Container>
      <AlertModal
        opened={shouldShowAlertModal}
        closeModal={closeAlertModal}
        hasError={!isEmailSent}
      >
        <Text component="p">
          {isEmailSent ? (
            <>
              Your request is in safe hands. Our team is reviewing it and will
              get back to you ASAP—promise! 💼. <br />
              <br />
              In the meantime, sit back, relax, and let us handle the rest. 😊
            </>
          ) : (
            <>
              We couldn’t send your request at the moment. Don’t worry, though!
              Try again in a few hours, and we’ll make sure to get back to you
              ASAP.
              <br /> <br />
              If it’s urgent, feel free to reach us on WhatsApp—we’re always
              ready to help! 📱
            </>
          )}
        </Text>
      </AlertModal>
      <Grid gutter={30} grow>
        <Grid.Col span={{ base: 12, md: 6 }}>
          {pageContent ? (
            <Stack
              gap={20}
              style={{ width: "100%", height: "100%" }}
              justify="center"
            >
              <HugeTitle style={hugeTitleStyles}>
                <div
                  dangerouslySetInnerHTML={{ __html: pageContent.header }}
                ></div>
              </HugeTitle>

              <DescriptionText size={desktopMediaQuery ? "lg" : "md"}>
                <div
                  dangerouslySetInnerHTML={{ __html: pageContent.body }}
                ></div>
              </DescriptionText>
              <KeyFeatures
                theme={theme}
                desktopMediaQuery={desktopMediaQuery}
              />
            </Stack>
          ) : (
            <Stack
              gap={20}
              style={{ width: "100%", height: "100%" }}
              justify="center"
            >
              <Stack gap={0}>
                <HugeTitle style={hugeTitleStyles}>Elevate Your</HugeTitle>
                <HugeTitle
                  style={{ ...hugeTitleStyles, ...animatedServiceStyles }}
                  className={`${
                    isSliding ? "blur-out-contract-bck" : "focus-in-expand-fwd"
                  }`}
                >
                  {animationServices.current[currentServiceIndex]}
                </HugeTitle>
                <HugeTitle style={hugeTitleStyles}>with Expert Help!</HugeTitle>
              </Stack>

              <DescriptionText size={desktopMediaQuery ? "lg" : "md"}>
                Welcome to{" "}
                <strong style={{ color: "#014d40" }}>
                  {process.env.REACT_APP_NAME}
                </strong>
                , the go-to platform trusted by students around the globe for
                reliable and expert assignment help.
              </DescriptionText>

              <KeyFeatures
                theme={theme}
                desktopMediaQuery={desktopMediaQuery}
              />
            </Stack>
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }} pr={{ base: 0, md: 3 }}>
          <GetHelpForm
            serviceData={serviceData}
            isLoading={isSendingEmail}
            onSubmit={handleSubmit}
            forceClearForm={shouldForceClearForm}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12 }} pr={{ base: 0 }} hiddenFrom="md">
          <Group hiddenFrom="md" flex={1} my={20}>
            <Metrics />
          </Group>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

function KeyFeatures({ desktopMediaQuery, theme }: any) {
  const keywordsListTextStyles = {
    color: "black",
    fontWeight: 400,
    fontSize: desktopMediaQuery ? "1.1rem" : ".8rem",
  };

  const keywordsListIconStyles = {
    height: rem(desktopMediaQuery ? 25 : 20),
    width: rem(desktopMediaQuery ? 25 : 20),
  };
  return (
    <List center spacing={desktopMediaQuery ? "sm" : "xs"} pt={0} mt={0}>
      <ListItem
        icon={
          <IconCircleCheck
            color={theme.colors.blue[6]}
            style={keywordsListIconStyles}
          />
        }
      >
        <HugeTitle style={keywordsListTextStyles}>
          100% Authentic Work
        </HugeTitle>
      </ListItem>
      <ListItem
        icon={
          <IconRobotOff
            color={theme.colors.green[6]}
            style={keywordsListIconStyles}
          />
        }
      >
        <HugeTitle style={keywordsListTextStyles}>AI-Free Content</HugeTitle>
      </ListItem>
      <ListItem
        icon={
          <IconClipboardOff
            color={theme.colors.orange[6]}
            style={keywordsListIconStyles}
          />
        }
      >
        <HugeTitle style={keywordsListTextStyles}>
          Plagiarism-Free Guarantee
        </HugeTitle>
      </ListItem>
      <ListItem
        icon={
          <IconLockPassword
            color={theme.colors.violet[6]}
            style={keywordsListIconStyles}
          />
        }
      >
        <HugeTitle style={keywordsListTextStyles}>
          Privacy and Confidentiality Assured
        </HugeTitle>
      </ListItem>
    </List>
  );
}
